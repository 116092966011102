<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  page: {
    title: "Evaluation details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title:                        "Evaluation details",
      employees:                    [],
      colleagues:                   [],
      employeesToEvaluate:          [],
      employeesToBeEvaluated:       [],
      categoriesToEvaluate:         [],

      colleaguesList:               [],
      employeesToBeEvaluatedList:   [],
      categoriesList:               [],

      nm_evaluation:                "",
      nm_company:                   "",
      nm_site:                      "",
      nm_department:                "",
      nm_responsibleEvaluator:      "",
      nm_creationAuthor:            "",
      dt_initialDate:               null,
      dt_finalDate:                 null,
      dt_creationDate:              null,
      dt_completedDate:             null,
      submitted:                    false,
      categories: [
        {id_category:1,   nm_category:'PERSONAL SKILLS'},
        {id_category:2,   nm_category:'LEADERSHIP'},
        {id_category:3,   nm_category:'CONTINUOUS IMPROVEMENT'},
        {id_category:4,   nm_category:'PLANNING AND ORGANIZATION'},
        {id_category:5,   nm_category:'SOLVING PROBLEMS'},
        {id_category:6,   nm_category:'COMMUNICATION'},
        {id_category:7,   nm_category:'AUTONOMY'},
        {id_category:8,   nm_category:'ENTERPRISE KNOWLEDGE'},
        {id_category:9,   nm_category:'CUSTOMER FOCUS'},
        {id_category:10,  nm_category:'INITIATIVE'},
        {id_category:11,  nm_category:'OBJECTIVE RESULTS'},
        {id_category:12,  nm_category:'DECISION MAKING'},
      ],
      items: [
        { text: "Evaluations",  href: "/" },
        { text: "Evaluations",  href: "/Evaluations/evaluations-details" },
        { text: "Details",   active: true }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.employees.length;
    }
  },
  watch:{},
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if(this.user.user.id_role >4 ){
      this.$router.push({ name:  'Error-401' });
    }

    if( this.$route.params.id ){
      this.getEvaluation();
    }
    this.getEmployeesForLog();
  },
  methods: {
    //Get the list of employees availables to be selected wheter to be an evaluator, or to be evaluated.
    async getEmployeesForLog(){
      Swal.fire({
        title: "Loading employees.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.user.user.id_company,
            idLevel:    this.user.user.id_levelJobPosition,
            op:         'le'
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.employees = response.data;

            this.getResponsibleEvaluator(this.employees, "nm_responsibleEvaluator", this.id_employee);
            this.getResponsibleEvaluator(this.employees, "nm_creationAuthor", this.id_userCreator);
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    //Get the list of colleagues to evaluate Responsible of the evaluation.
    async getColleagues(){
      Swal.fire({
        title: "Loading colleagues.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.user.user.id_company,
            idLevel:    this.user.user.id_levelJobPosition,
            idJobTitle: this.user.user.id_jobTitle,
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.colleagues = response.data;

            this.selectIndividualRow(this.colleagues, this.colleaguesList);
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    //Get the list of colleagues to evaluate Responsible of the evaluation.
    async getEmployees(){
      Swal.fire({
        title: "Loading employees.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.id_company,
            idManager:  this.id_employee,
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.employees = response.data;

            this.selectIndividualRow(this.employeesToBeEvaluated, this.employeesToBeEvaluatedList);
            this.selectIndividualRow(this.employeesToEvaluate, this.employeesToEvaluateList);
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    async getEvaluation(){
      Swal.fire({
        title: "Loading evaluation data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/evaluations.php',
        {
          params: {
            request:  'getEvaluationByID',
            id:       this.$route.params.id
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          this.evaluation = response.data;

          this.id_evaluationType        = this.evaluation.id_evaluationType;
          this.dt_creationDate          = this.evaluation.dt_creationDate;
          this.dt_initialDate           = this.evaluation.dt_initialDate;
          this.dt_finalDate             = this.evaluation.dt_finalDate;
          this.dt_completedDate         = this.evaluation.dt_completedDate;
          this.id_company               = this.evaluation.id_company;
          this.id_site                  = this.evaluation.id_site;
          this.id_department            = this.evaluation.id_department;
          this.id_employee              = this.evaluation.id_responsibleEvaluator;
          this.id_userCreator           = this.evaluation.id_userCreator;

          this.responsibleEvaluator     = [
            {
              id_company: this.evaluation.id_company,
              id_site: this.evaluation.id_site,
              id_department: this.evaluation.id_department,
              id_responsibleEvaluator: this.evaluation.id_responsibleEvaluator,
              id_employee: this.evaluation.id_responsibleEvaluator,
            }
          ];

          this.employeesToBeEvaluated   = this.evaluation.id_employeesToBeEvaluated;
          this.colleagues               = this.evaluation.id_colleaguesEvaluators;
          this.employeesToEvaluate      = this.evaluation.id_employeesEvaluators;
          this.categoriesToEvaluate     = this.evaluation.id_categories;

          this.nm_evaluation            = this.getEvaluationName(this.evaluation.id_evaluationType);

          this.getEmployees();
          this.selectIndividualCategory();
          

          if(this.id_evaluationType >= 2 && this.id_evaluationType <=4 ){
            this.getColleagues();
          }
          
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    getResponsibleEvaluator(array, reference, idToCheck){
      let app = this;
      array.forEach(function(item){
        if(Array.isArray(idToCheck)){
          idToCheck.forEach(function(id){
            if(id == item.id_employee){
              app[reference] = id.nm_employee;
            }
          });
        }else{
          if(idToCheck == item.id_employee){
            app[reference] = item.nm_employee;
          }
        }
      });
    },

    getEvaluationName(idEvaluationType){
      let name = "";
      switch(idEvaluationType){
        case 1: name="90 Degrees"; break;
        case 2: name="180 Degrees"; break;
        case 3: name="270 Degrees"; break;
        case 4: name="360 Degrees"; break;
      }
      return name;
    },

    selectIndividualRow(comparison, destiny){
      this.employees.forEach(function(item){
        if(Array.isArray(comparison)){
          comparison.forEach(function(id){
            if(id == item.id_employee){
              destiny.push(item.nm_employee);
            }
          });
        }
      });
    },

    selectIndividualCategory(){
      let app = this;
      this.categories.forEach(function(category){
        app.categoriesToEvaluate.forEach(function(cat){
          if(cat == category.id_category){
            app.categoriesList.push(category.nm_category);
          }
        });
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-header bg-dark p-1 pt-2 pl-2">
                        <h6 class="text-white">Evaluation History</h6>
                      </div>
                      <div class="card-body p-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul>
                              <li>
                                <h6 class="m-0 p-0">Evaluation Type:</h6>
                                <p class="m-0 mb-2 p-0">{{nm_evaluation}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Evaluation Responsible:</h6>
                                <p class="m-0 mb-2 p-0">{{nm_responsibleEvaluator}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Created on:</h6>
                                <p class="m-0 mb-2 p-0">{{dt_creationDate}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Created By:</h6>
                                <p class="m-0 mb-2 p-0">{{nm_creationAuthor}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Initial Date:</h6>
                                <p class="m-0 mb-2 p-0">{{dt_initialDate}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Final Date:</h6>
                                <p class="m-0 mb-2 p-0">{{dt_finalDate}}</p>
                              </li>
                              <li>
                                <h6 class="m-0 p-0">Completed Date:</h6>
                                <p v-if="dt_completedDate != null" class="m-0 mr-3 p-1 bg-success text-white">{{dt_completedDate}}</p>
                                <p v-if="dt_completedDate == '' || dt_completedDate === null" class="m-0 mr-3 p-1 bg-danger text-white">Not yet completed.</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>  
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-6">
                    <div v-if="colleagues.length > 0" class="card">
                      <div class="card-header bg-dark p-1 pt-2 pl-2">
                        <h6 class="text-white">Evaluators colleagues</h6>
                      </div>
                      <div class="card-body p-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul>
                              <li v-for="(colleague, index) in colleaguesList" v-bind:key="index">{{colleague}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>  
                    </div>

                    <div v-if="employeesToEvaluate.length > 0" class="card">
                      <div class="card-header bg-dark p-1 pt-2 pl-2">
                        <h6 class="text-white">Evaluators employees</h6>
                      </div>
                      <div class="card-body p-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul>
                              <li v-for="(emp, index) in employeList" v-bind:key="index">{{emp}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>  
                    </div>

                    <div v-if="employeesToBeEvaluated.length > 0" class="card">
                      <div class="card-header bg-dark p-1 pt-2 pl-2">
                        <h6 class="text-white">Employees to be Evaluated</h6>
                      </div>
                      <div class="card-body p-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul>
                              <li v-for="(emp, index) in employeesToBeEvaluatedList" v-bind:key="index">{{emp}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>  
                    </div>

                    <div v-if="categoriesToEvaluate.length > 0" class="card">
                      <div class="card-header bg-dark p-1 pt-2 pl-2">
                        <h6 class="text-white">Categories to be Evaluated</h6>
                      </div>
                      <div class="card-body p-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul>
                              <li v-for="(cat, index) in categoriesList" v-bind:key="index">{{cat}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>  
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="col-lg-12">
                      <div v-if="employeesToBeEvaluated.length > 0" class="card">
                        <div class="card-header bg-dark p-1 pt-2 pl-2">
                          <h6 class="text-white">Who has completed the evaluation?</h6>
                        </div>
                        <div class="card-body p-1">
                          <div class="row">
                            <div class="col-lg-12">
                              <ul>
                                <!--
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                <li><div class="row"><div class="col-lg-8">{{}} on:</div><div class="col-lg-3 bg-success text-white">{{}}</div></div></li>
                                -->
                              </ul>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .form-control:disabled{
    background-color: #ecf0f1 !important;
  }
  ul{
    padding-left: 20px;
    list-style-type: '- ';
  }
</style>